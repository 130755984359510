/**
 * ORIGINAL FILE = dateUtils.ts (TYPESCRIPT)
 * DO NOT EDIT THE JS FILE!
 */
const dateUtils = {
    dateToLocalDate: (date) => LocalDate.from(nativeJs(date)),
    dateToLocalDateTime: (date) => LocalDateTime.from(nativeJs(date)),
    ymdStringToLocalDate: (date) => LocalDate.parse(date),
    ymdStringToDate: (date) => JSJoda.convert(LocalDate.parse(date)).toDate(),
    localDateToDate: (localDate) => JSJoda.convert(localDate).toDate(),
    localDateToMDYString: (localDate) => localDate.format(JSJoda.DateTimeFormatter.ofPattern("M/d/yyyy")),
};
angular.module("dashboard").constant("dateUtils", dateUtils);
export {};
