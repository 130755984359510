angular
  .module("dashboard")
  .factory("CaregiverTrainingCenterService", ($http, Consts, wildcard) => ({
    /**
     * Get bundles summary
     */
    getBundlesSummary: ({ agencyId, agencyMemberId, caregiverId }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/training_center/all_bundles_summary";

      return $http
        .get(wildcard(url, agencyId, agencyMemberId, caregiverId))
        .then((res) => res.data.bundles);
    },

    /**
     * Get certificate url
     */
    getCertificateUrl: ({
      agencyId,
      agencyMemberId,
      caregiverId,
      trainingCenterBundleDueDateId,
    }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/training_center/" +
        "bundle_due_dates/:trainingCenterBundleDueDateId/certificate_pdf";

      return $http
        .get(
          wildcard(
            url,
            agencyId,
            agencyMemberId,
            caregiverId,
            trainingCenterBundleDueDateId
          )
        )
        .then((res) => res.data.url);
    },

    /**
     * Edit training center item exempt
     */
    editItemExempt: ({
      agencyId,
      agencyMemberId,
      caregiverId,
      trainingCenterBundleDueDateId,
      trainingCenterBundleItemId,
      params,
    }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/training_center/" +
        "bundle_due_dates/:trainingCenterBundleDueDateId/" +
        "bundle_items/:trainingCenterBundleItemId/edit_exempt";

      return $http
        .post(
          wildcard(
            url,
            agencyId,
            agencyMemberId,
            caregiverId,
            trainingCenterBundleDueDateId,
            trainingCenterBundleItemId
          ),
          params
        )
        .then((res) => res.data.bundles);
    },

    /**
     * Edit training center bundle exempt
     */
    editBundleExempt: ({
      agencyId,
      agencyMemberId,
      caregiverId,
      trainingCenterBundleDueDateId,
      params,
    }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/training_center/" +
        "bundle_due_dates/:trainingCenterBundleDueDateId/edit_exempt";

      return $http
        .post(
          wildcard(
            url,
            agencyId,
            agencyMemberId,
            caregiverId,
            trainingCenterBundleDueDateId
          ),
          params
        )
        .then((res) => res.data.bundles);
    },
    allowWatchDuringShift: ({
      agencyId,
      agencyMemberId,
      caregiverId,
      bundleDueDateId
    }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId" +
        "/training_center/due_dates/:dueDateId/onshift_override";

      return $http.post(
        wildcard(url, agencyId, agencyMemberId, caregiverId, bundleDueDateId)
      );
    },
    preventWatchDuringShift: ({
      agencyId,
      caregiverId,
      bundleDueDateId
    }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/caregivers/:caregiverId" +
        "/training_center/due_dates/:dueDateId/remove_onshift_override";

      return $http.delete(
        wildcard(url, agencyId, caregiverId, bundleDueDateId)
      );
    },
    /**
     * Send notification reminder to caregiver with specific bundle title in notification text
     */
    sendReminderToCaregiver: ({
      agencyId,
      agencyMemberId,
      caregiverId,
      bundleId,
    }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId" +
        "/training_center/bundles/:bundleId/send_training_center_reminder";

      return $http.post(
        wildcard(url, agencyId, agencyMemberId, caregiverId, bundleId)
      );
    },

    /**
     * Add test attempt
     */
    addTestAttempt: ({
      agencyId,
      agencyMemberId,
      caregiverId,
      trainingCenterBundleDueDateId,
      trainingCenterBundleItemId,
      params,
    }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/training_center/" +
        "bundle_due_dates/:trainingCenterBundleDueDateId/" +
        "bundle_items/:trainingCenterBundleItemId/add_test_attempts";

      return $http
        .post(
          wildcard(
            url,
            agencyId,
            agencyMemberId,
            caregiverId,
            trainingCenterBundleDueDateId,
            trainingCenterBundleItemId
          ),
          params
        )
        .then((res) => res.data.bundles);
    },

    /**
     * Get test submissions
     */
    getTestSubmissions: ({
      agencyId,
      agencyMemberId,
      caregiverId,
      trainingCenterBundleDueDateId,
      trainingCenterBundleItemId,
    }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/" +
        "training_center/bundle_due_dates/:trainingCenterBundleDueDateId/" +
        "bundle_items/:trainingCenterBundleItemId/test_submissions";

      return $http
        .get(
          wildcard(
            url,
            agencyId,
            agencyMemberId,
            caregiverId,
            trainingCenterBundleDueDateId,
            trainingCenterBundleItemId
          )
        )
        .then((res) => res.data.submissions);
    },

    /**
     * Get test submission PDF URL
     */
    getTestSubmissionPdfUrl: ({
      agencyId,
      agencyMemberId,
      caregiverId,
      trainingCenterBundleDueDateId,
      testSubmissionId,
    }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/" +
        "training_center/bundle_due_dates/:trainingCenterBundleDueDateId/" +
        "test_submissions/:testSubmissionId/generate_pdf";

      return $http
        .get(
          wildcard(
            url,
            agencyId,
            agencyMemberId,
            caregiverId,
            trainingCenterBundleDueDateId,
            testSubmissionId
          )
        )
        .then((res) => res.data.pdfUrl);
    },
  }));
