angular
    .module("dashboard")
    .factory("planOfCareHHAXService", function ($rootScope, $http, Consts) {
        const service = {};

        service.getMedflytPlanOfCareItems = () => {
            return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/coordinator/${$rootScope.agencyMemberId}/plan_of_care_settings`).then(res => {
                return res.data;
            });
        }

        service.getPayerHHAXPlanOfCareItems = (payerId) => {
            return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/hhax_poc/${payerId}`).then(res => {
                return res.data;
            });
        }

        service.createHHAXMedflytPOCMapping = (mappingUpsertParams) => {
            return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/hhax_medflyt_poc_mapping`, mappingUpsertParams);
        }


        return service;
    });