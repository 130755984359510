angular
  .module("dashboard")
  .factory("hhaxIntegrationService", ($http, Consts, $rootScope) => ({
    getPatientFiles: (integrationDatePicker, selectedOffices, selectedPayers) => {
      const baseUrl = `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/hhax_integration_patient_dem`;
      const url = buildNonBillingIntegrationUrl(baseUrl, integrationDatePicker, selectedOffices, selectedPayers);

      return $http.get(url);
    },

    getAuthorizationFiles: (integrationDatePicker, selectedOffices, selectedPayers) => {
      const baseUrl = `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/hhax_integration_authorization`;
      const url = buildNonBillingIntegrationUrl(baseUrl, integrationDatePicker, selectedOffices, selectedPayers);

      return $http.get(url);
    },

    getPOCFiles: (integrationDatePicker, selectedOffices, selectedPayers) => {
      const baseUrl = `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/hhax_integration_poc`;
      const url = buildNonBillingIntegrationUrl(baseUrl, integrationDatePicker, selectedOffices, selectedPayers);

      return $http.get(url);
    },

    getUnlinkedPatients: () => {
      const url = `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/hhax_integration_unlinked_patients`;
      return $http.get(url);
    },

    processRecords: (recordsIds, fileType, markType) => {
      const url = `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/hhax_integration_mark_records`;

        return $http.patch(url, {
          records: recordsIds,
          fileType: fileType,
          markType: markType,
        });
    },

    getHHAXIntegrationPayers: () => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/hhax_integration_payers`).then(res => {
          return res.data;
      });
    },

    getBillingRejections: (dates, selectedOffices, selectedContracts) => {
      const baseUrl = `${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/hhax_integration_billing_rejections`;
      const url = buildBillingIntegrationURL(baseUrl, dates, selectedOffices, selectedContracts);

      return $http.get(url).then(res => {
        return res.data;
      });
    }
  }));

const buildNonBillingIntegrationUrl = (baseUrl, integrationDatePicker, selectedOffices, selectedPayers) => {
  const fromParam = JSJoda.LocalDate.from(nativeJs(integrationDatePicker.from));

  const toParam = JSJoda.LocalDate.from(nativeJs(integrationDatePicker.to));

  let url = `${baseUrl}?from=${fromParam}&to=${toParam}`;

  if (selectedOffices.length > 0) {
    url = url.concat(`&offices=${selectedOffices.map(({ id }) => id).join("|")}`);
  }
  if (selectedPayers.length > 0) {
    url = url.concat(`&payers=${selectedPayers.map(({ id }) => id).join("|")}`);
  }

  return url;
}

const buildBillingIntegrationURL = (baseUrl, dates, selectedOffices, selectedContracts) => {
  const fromParam = JSJoda.LocalDate.from(nativeJs(dates.from));

  const toParam = JSJoda.LocalDate.from(nativeJs(dates.to));

  let url = `${baseUrl}?from=${fromParam}&to=${toParam}`;

  if (selectedOffices.length > 0) {
    url = url.concat(`&offices=${selectedOffices.map(({ id }) => id).join("|")}`);
  }
  if (selectedContracts.length > 0) {
    url = url.concat(`&contracts=${selectedContracts.map(({ id }) => id).join("|")}`);
  }

  return url;
}
