app.service("wildcard", function() {
  return (url, ...args) => {
    const wildcards = url.match(/:([A-z])+/gm);

    for (var i = 0; i < args.length; i++) {
      url = url.replace(wildcards[i], args[i]);
    }

    return url;
  };
});
