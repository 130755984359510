angular
    .module("dashboard")
    .factory("physiciansService", function ($rootScope, $http, DatabaseApi, Consts) {
        /* Decalare factory return object */
        const service = {};

        // ============ Physicians endpoints ========
        service.createPhysician = (physicianCreateParams) => {
            return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/physicians`, physicianCreateParams);
        }

        service.editPhysician = (physicianId, physicianEditParams) => {
            return $http.put(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/physicians/${physicianId}`, physicianEditParams);
        }

        service.removePhysician = (physicianId) => {
            return $http.delete(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/physicians/${physicianId}`);
        }

        // ============ Patient physicians endpoints ========
        service.getPatientPhysicians = (patientId) => {
            return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patients/${patientId}/physicians`).then(res => {
                return res.data;
            });
        }

        service.createPatientPhysician = (patientId, physicianCreateParams) => {
            return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patients/${patientId}/physicians`, physicianCreateParams);
        }

        service.updatePatientPhysician = (patientId, physicianPatientAssocId, patientPhysicianEditParams) => {
            return $http.patch(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patients/${patientId}/patient_physicians/${physicianPatientAssocId}`, patientPhysicianEditParams);
        }

        service.removePatientPhysician = (patientId, physicianPatientAssocId) => {
            return $http.delete(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/patients/${patientId}/patient_physicians/${physicianPatientAssocId}`);
        }


        // ============ Physicians Utils ========
        service.getPhysiciansBySearchString = (searchText) => {
            const physicians = DatabaseApi.physicians();
            const items = []
            for (const p of physicians) {
                if (p.firstName.toLowerCase().includes(searchText) ||
                    p.lastName.toLowerCase().includes(searchText) ||
                    p.npi.toLowerCase().includes(searchText) ||
                    p.address && p.address.toLowerCase().includes(searchText) ||
                    p.phone && p.phone.includes(searchText)
                ) items.push(p);

                if (items.length === 10) break;
            }

            return items;
        }

        return service;
    });