angular
  .module("dashboard")
  .factory("CopyCertificationSettingsService", ($http, Consts, wildcard) => ({
    /**
     * Copy the certification settings
     */
    copyCertificationSettings: ({ agencyId, agencyMemberId, targetCert, sourceCert }) => {
      const url =
        Consts.api +
        "hr/agencies/:agencyId/agency_members/:agencyMemberId/certification/copy/" +
        "target/:targetCertification/source/:sourceCertification";
      return $http
        .post(wildcard(url, agencyId, agencyMemberId, targetCert, sourceCert))
        .then(() => true)
        .catch(err => {
          throw err.data.error;
        });
    },
    copyApplicationSettingsFromTemplate: ({ agencyId, agencyMemberId, targetCert }) => {
      const url =
        Consts.api +
        "hr/agencies/:agencyId/agency_members/:agencyMemberId/certification/copy/target/:targetCertification";
      return $http
        .post(wildcard(url, agencyId, agencyMemberId, targetCert))
        .then(() => true)
        .catch(err => {
          throw err.data.error;
        });
    }
  }));
