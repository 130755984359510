angular
  .module("dashboard")
  .factory(
    "agencyBranchService",
    function ($rootScope, wildcard, $http, Consts) {
      /* Decalare factory return object */
      const service = {};

      // ============ Agency Branch ================

      /**
       * Edit agency branch subjects
       */
      service.editAgencyBranch = (
        agencyId,
        agencyMemberId,
        agencyBranchId,
        agencyBranchEditParams
      ) => {
        return $http.put(
          `${Consts.api}agencies/${agencyId}/agency_members/${agencyMemberId}/branch/${agencyBranchId}`,
          agencyBranchEditParams
        );
      };

      /**
       * Create agency branch subjects
       */
      service.createAgencyBranch = (
        agencyId,
        agencyMemberId,
        agencyBranchCreateParams
      ) => {
        return $http.post(
          `${Consts.api}agencies/${agencyId}/agency_members/${agencyMemberId}/branch`,
          agencyBranchCreateParams
        );
      };

      // ===========================================================
      // ============ Caregiver Agency Branch Assoc ================
      // ===========================================================

      /**
       * Update caregivers branches list
       */
      service.updateCaregiversBranches = (
        agencyId,
        agencyMemberId,
        caregiverId,
        data
      ) => {
        const url =
          Consts.api +
          "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/branches";
        return $http.put(
          wildcard(url, agencyId, agencyMemberId, caregiverId),
          data
        );
      };

      // ===========================================================
      // ============ Patient Agency Branch Assoc ================
      // ===========================================================

      /**
       * Update patients branches list
       */
      service.updatePatientBranches = (
        agencyId,
        agencyMemberId,
        patientId,
        data
      ) => {
        const url =
          Consts.api +
          "agencies/:agencyId/agency_members/:agencyMemberId/patients/:patientId/branches";
        return $http.put(
          wildcard(url, agencyId, agencyMemberId, patientId),
          data
        );
      };

      return service;
    }
  );
