if ("serviceWorker" in navigator) {
  if (window.appEnv === "development") {
    console.log("You are in development mode. Skipped service worker registration");
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let registration of registrations) {
        registration.unregister();
        console.log("deleted sw.js", registration);
      }
    });
  }

  if (window.appEnv === "production") {
    // Register a service worker hosted at the root of the
    // site using the default scope.
    navigator.serviceWorker.register("/sw.js").then(
      function(registration) {
        console.log("Service worker registration succeeded:", registration);
      },
      /*catch*/ function(error) {
        console.log("Service worker registration failed:", error);
      }
    );
  }
  
} else {
  console.log("Service workers are not supported.");
}
