"use strict";

angular
  .module("dashboard")
  .filter("activeAgencySettings", (AgencySettingsService) => {
    return (agencyData) => {
      const settings = [
        ...Object.keys(AgencySettingsService.getSettings()),
        ...Object.keys(AgencySettingsService.getSubscriptions()),
      ];

      return settings.filter((settingKey) => {
        const setting = agencyData[settingKey];

        if (typeof setting === "boolean") {
          return setting === true;
        }

        if (setting && setting.enabled) {
          return true;
        }

        return false;
      }).length;
    };
  });
