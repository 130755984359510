"use strict";

/**
 * An example usage of this modal:
 * 
 * const modal = mfModal.create({
 *      subject: "Delete item",
 *      options: [
 *        { id: 1, label: "Delete selected items (4)" },
 *        { id: 2, label: "Delete selected items going forward" },
 *        { id: 3, label: "Delete selected items until a specific date" },
 *      ],
 *      message: `Are you sure you want to delete 4 visits?\n All of your deleted visits will be saved on your calendar.`,
 *      layoutOrder: ["message", "options"],
 *      cancelLabel: "Cancel",
 *      confirmLabel: "Delete",
 *      onConfirm: ({ selectedOption }) => {
 *        modal.setLoading(true);
 *        $timeout(() => {
 *          if (selectedOption) {
 *            modal.close();
 *            return;
 *          }

 *          modal.update({
 *            isLoading: false,
 *            message: "Please select an option!",
 *          });
 *        }, 1000);
 *      },
 *      onCancel: () => console.log("Canceled"),
 *      onComplete: () => console.log("Complete"),
 *    });
 */
angular.module("dashboard").factory("mfModal", ($uibModal) => ({
  create({
    variant,
    subject,
    message,
    options,
    optionIsRequired,
    cancelLabel,
    extraActionLabel,
    extraAction,
    confirmLabel,
    size,
    layoutOrder,
    preventClose,
    preventBackdropClose,
    hideCancelButton,
    onConfirm,
    onCancel,
    onComplete,
    onExtraAction,
  }) {
    const state = {
      variant: variant || "info",
      subject,
      message,
      options,
      optionIsRequired,
      cancelLabel,
      extraActionLabel,
      confirmLabel,
      hideCancelButton: hideCancelButton || false,
      extraAction: !!extraAction,
      onConfirm,
      onCancel,
      onComplete,
      onExtraAction,
      isLoading: false,
      selectedOption: undefined,
    };

    const modal = $uibModal.open({
      templateUrl: "admin/views/mf-modal.html",
      size: size || "md",
      windowClass: "center-center mf-modal-window",
      backdrop: preventClose === true || preventBackdropClose === true ? "static" : true,
      keyboard: preventClose === true ? false : true,
      resolve: {
        state: () => state,
      },
      controller: ($scope) => {
        $scope.onClickConfirm = () => {
          if (onConfirm) {
            onConfirm(state);
          }

          if (onComplete) {
            onComplete(state);
          }
        };

        $scope.onClickExtraAction = () => {
          if (onExtraAction) {
            onExtraAction(state);
          }

          if (onComplete) {
            onComplete(state);
          }
        };

        $scope.onClickCancel = () => {
          if (!preventClose) {
            modal.close();
          }

          if (onCancel) {
            onCancel(state);
          }

          if (onComplete) {
            onComplete(state);
          }
        };

        $scope.layoutOrder = layoutOrder || ["message", "options"];
        $scope.optionsOrder = $scope.layoutOrder.indexOf("options");
        $scope.messageOrder = $scope.layoutOrder.indexOf("message");
        $scope.modal = state;
      },
    });

    return {
      setLoading: (isLoading) => (state.isLoading = isLoading),
      update: (newPayload) => {
        return Object.entries(newPayload).forEach(
          ([key, value]) => (state[key] = value)
        );
      },
      close: () => modal.close(),
    };
  },

  createSimple({
    variant = "danger",
    subject = "Error",
    message = "",
    confirmLabel = "OK",
  }) {
    const modal = this.create({
      variant: variant,
      subject: subject,
      message: message,
      layoutOrder: ["message"],
      confirmLabel: confirmLabel,
      hideCancelButton: true,
      onComplete: () => modal.close()
    });
  },
}));
