app.constant("noteConsts", {
    NoteTypes: {
      PROFILE_NOTE: 'profile_note',
      MISSED_VISIT_INSTANCE: 'missed_visit_instance',
      PATIENT_AUTHORIZATION: 'patient_authorization',
      CAREGIVER_PTO: 'caregiver_pto',
      VISIT_MANUAL_CLOCK_TIME: 'visit_manual_clock_time',
      VISIT_DISTANCE_APPROVAL: "visit_distance_approval",
      BULK: 'bulk',
      CALENDAR: 'calendar',
      VISIT_INSTANCE: "visit_instance",
      ADJUSTMENT_APPROVAL: "adjustment_approval",
      PATIENT_STATUS: "patient_status",
      CAREGIVER_EXCLUSION: "caregiver_exclusion",
      VISIT_MANUAL_REMOVE: "visit_manual_remove",
      CAREGIVER_STATUS_CHANGE: "caregiver_status_change",
      CAREGIVER_ABSENCE: "caregiver_absence",
    },

    NoteTypesTranslations: {
      profile_note: 'Profile Note',
      missed_visit_instance: 'Missed Visit',
      patient_authorization: 'Patient Authorization',
      caregiver_pto: 'Caregiver PTO',
      visit_manual_clock_time: 'Visit Manual Clock Time',
      visit_distance_approval: 'Visit Distance Approval',
      bulk: 'Bulk Note',
      calendar: "Calendar",
      visit_instance: "Visit",
      adjustment_approval: "Adjustment Approval",
      patient_status: "Patient Status Change",
      caregiver_exclusion: "Caregiver Exclusion",
      visit_manual_remove: "Visit Manual Remove",
      caregiver_status_change: "Caregiver status change",
      caregiver_absence: "Caregiver Absence",
    },

    DEFAULT_STATUS: 'NONE',
	
    statuses: [
      { id: "NONE", text: "" },
      { id: "VIEWED", text: "Viewed" },
      { id: "IN_PROGRESS", text: "In progress" },
      { id: "DONE", text: "Done" }
    ]
  });
