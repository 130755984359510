"use strict";

app.factory(
  "TrainingCenterClassroomService",
  ($rootScope, DatabaseApi, wildcard) => ({
    /**
     * Get all classrooms
     */
    getClassrooms() {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/classrooms",
        agencyId,
        agencyMemberId
      );

      return DatabaseApi.get(endpoint).then(
        ({ data: { classrooms } }) => classrooms
      );
    },

    /**
     * Get specific classroom
     */
    getClassroom(classroomId) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/classrooms/:classroomId",
        agencyId,
        agencyMemberId,
        classroomId
      );

      return DatabaseApi.get(endpoint).then(({ data }) => {
        const { startDateTime, endDateTime } = data;
        const from = new Date(startDateTime);
        const to = new Date(endDateTime);

        const classroom = {
          ...data,
          date: from,
          from,
          to,
        };

        return classroom;
      });
    },

    /**
     * Get all published and active bundles
     */
    getBundles() {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/classroom_bundles",
        agencyId,
        agencyMemberId
      );

      return DatabaseApi.get(endpoint).then(({ data: { bundles } }) => bundles);
    },

    /**
     * Create classroom
     */
    createClassroom(classroom) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/classrooms",
        agencyId,
        agencyMemberId
      );

      const { from, to } = classroom;
      const startDateTime = LocalDateTime.from(nativeJs(from));
      const endDateTime = LocalDateTime.from(nativeJs(to));

      classroom = {
        ...classroom,
        startDateTime,
        endDateTime,
      };

      return DatabaseApi.post(endpoint, classroom).then(
        ({ data: { id } }) => id
      );
    },

    /**
     * Edit classroom
     */
    editClassroom(classroomId, classroom) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/classrooms/:classroomId",
        agencyId,
        agencyMemberId,
        classroomId
      );

      const { from, to } = classroom;
      const startDateTime = LocalDateTime.from(nativeJs(from));
      const endDateTime = LocalDateTime.from(nativeJs(to));

      classroom = {
        ...classroom,
        startDateTime,
        endDateTime,
      };

      return DatabaseApi.put(endpoint, classroom);
    },

    /**
     * Add caregiver to classroom
     */
    addCaregiver({ classroomId, caregiverId }) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/classrooms/:classroomId/caregivers",
        agencyId,
        agencyMemberId,
        classroomId
      );

      const caregiver = {
        caregiverId,
        notes: null,
      };

      return DatabaseApi.post(endpoint, caregiver).then(
        ({ data: { caregiver } }) => caregiver
      );
    },

    /**
     * Un/complete classroom for caregiver
     */
    caregiverCompletion({ classroomId, caregiverId, completed }) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/classrooms/:classroomId/caregivers/:caregiverId/completed/:completed",
        agencyId,
        agencyMemberId,
        classroomId,
        caregiverId,
        completed,
      );

      return DatabaseApi.put(endpoint);
    },

    /**
     * Complete classroom for all caregivers
     */
    completeAllCaregivers(classroomId) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/classrooms/:classroomId/completed",
        agencyId,
        agencyMemberId,
        classroomId
      );

      return DatabaseApi.put(endpoint);
    },

    /**
     * Remove caregiver
     */
    removeCaregiver({ classroomId, caregiverId }) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/classrooms/:classroomId/caregivers/:caregiverId",
        agencyId,
        agencyMemberId,
        classroomId,
        caregiverId
      );

      return DatabaseApi.delete(endpoint);
    },

    /**
     * Remove classroom
     */
    removeClassroom(classroomId) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/training_center/classrooms/:classroomId",
        agencyId,
        agencyMemberId,
        classroomId
      );

      return DatabaseApi.delete(endpoint);
    },
  })
);
