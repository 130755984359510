angular
  .module("dashboard")
  .factory("complianceService", ($rootScope, $http, Consts) => {
    /* Decalare factory return object */
    const service = {};

    service.compliantStatuses = ['Compliant', 'Resolved', 'Not due yet'];

      // ============ Compliance Calls ========
      service.getComplianceItemRecordsInfo = (caregiverId, caregiverDocumentTypeId) => {
          const url = "hr/agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/compliance_items/:caregiverDocumentTypeId/records_info"
              .replace(":agencyId", $rootScope.agencyId)
              .replace(":agencyMemberId", $rootScope.agencyMemberId)
              .replace(":caregiverId", caregiverId)
              .replace(":caregiverDocumentTypeId", caregiverDocumentTypeId);
          return $http.get(`${Consts.api}${url}`);
      };

      // ============ Compliance Group Endpoints ========
      service.createComplianceGroupInstances = (caregiverId, body) => {
          const url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/multiple_compliance_items"
              .replace(":agencyId", $rootScope.agencyId)
              .replace(":agencyMemberId", $rootScope.agencyMemberId)
              .replace(":caregiverId", caregiverId);
          return $http.post(`${Consts.api}${url}`, body);
      };


    // ============ Utils ========
    // We have to do this because angularJS can't handle this in the view file,
    // since only <tbody> is allowed to be the parent of a <tr>, and you can't
    // have nested <tbody>s
    service.flattenFollowupDocuments = (data, depth, root, parent) => {
        const result = [];
        depth = depth || 0;

        data.forEach(doc => {
            const docDepth = depth + (doc.depth || 0);

            doc.root = root || doc.root || doc;
            doc.parent = parent || doc.parent || doc;

            result.push({...doc, depth: docDepth});

            if (doc.followupDocument) {
                result.push(
                    ...service.flattenFollowupDocuments(
                        [doc.followupDocument],
                        docDepth + 1,
                        doc.root,
                        doc
                    )
                );
            }
        });

        return result;
    };

    service.flattenGroupDocuments = (data, depth, groupRoot, parent) => {
        const result = [];
        depth = depth || 0;

        data.forEach(doc => {
            doc.root = groupRoot || doc;
            doc.parent = parent || doc;
            
            result.push({...doc, depth});

            if (doc.children) {
                result.push(
                    ...service.flattenGroupDocuments(
                        doc.children,
                        depth + 1,
                        doc.root,
                        doc
                    )
                );
            }
        });

        return result;
    };

    service.buildGroupItemsTree = (items) => {
        const map = new Map();
        const dataTree = [];
    
        items.forEach((item) => map.set(item.caregiverDocumentTypeId, { ...item, children: [] }));
    
        items.forEach((item) => {
            const node = map.get(item.caregiverDocumentTypeId);

            node.isChild = Boolean(item.parentCaregiverDocumentTypeId) && map.has(item.parentCaregiverDocumentTypeId);
    
            if (node === undefined) {
                throw new Error("node cannot be undefined");
            }
    
            if (node.isChild || node.isFollowupDocument) {
                const parent = node.isChild
                    ? map.get(item.parentCaregiverDocumentTypeId)
                    : map.get(item.followupParentId);
    
                if (parent !== undefined) {
                    parent.children.push(node);
                }
            } else {
                dataTree.push(node);
            }
        });
    
        return dataTree;
    };

    service.getHasNonGroupDescendantSet = (dataTree) => {
        const set = new Set();

        dataTree.forEach(item => {
            if (service.hasNonGroupDescendant(item)) {
                set.add(item.caregiverDocumentTypeId);
            }
        });

        return set;
    };

    service.hasNonGroupDescendant = (item) => {
        if (item.children && item.children.length > 0) {
            for (const child of item.children) {
                if (service.hasNonGroupDescendant(child)) {
                    return true;
                }
            }
        }

        return !item.groupType && item.status !== "Missing";
    };

    service.calcShowGroupItems = (items) => {
        items.forEach(item => item.showCalculatedByGroup = service.calcShowGroupItem(item, true));

        return items;
    };

    service.calcShowGroupItem = (item, isRoot) => {
        if (item.children) {
            item.children.forEach(
                (child) => child.showCalculatedByGroup = service.calcShowGroupItem(child)
            );
        }
    
        return isRoot || item.isCompliant || (item.instances.length > 0 && !item.groupType) || (item.groupType && service.hasShownChild(item)) || item.isFollowupDocument;
    };

    service.calcShowGroupItemsIncompliances = (items) => {
        items.forEach(item => item.showCalculatedByGroup = service.calcShowGroupIncompliantItems(item, true));

        return items;
    };

    service.calcShowGroupIncompliantItems = (item, isRoot) => {
        if (item.children) {
            item.children.forEach(
                (child) => child.showCalculatedByGroup = service.calcShowGroupIncompliantItems(child)
            );
        }

        const isCompliantStatus = ["Compliant", "Resolved", "Not due yet"].includes(item.status);
    
        return (isRoot && !isCompliantStatus) || (!isCompliantStatus && !item.groupType && (!item.parentCaregiverDocumentTypeId || item.status !== "Missing")) || service.hasShownChild(item);
    };

    service.hasShownChild = (item) => {
        if (item.children) {
            for (const child of item.children) {
                if (service.hasShownChild(child)) {
                    return true;
                }
            }
        }

        return item.showCalculatedByGroup || (item.followupDocument && service.hasShownChild(item.followupDocument));
    };

    service.calcShowIncompliantFollowupItems = (items) => {
        items.forEach(item => item.show = service.calcShowIncompliantFollowupItem(item));

        return items;
    };

    service.calcShowIncompliantFollowupItem = (item, isFollowup) => {
        if (item.followupDocument) {
            return item.followupDocument.show = service.calcShowIncompliantFollowupItem(item.followupDocument, true);
        }

        const isCompliantStatus = ["Compliant", "Resolved", "Not due yet"].includes(item.status);

        item.isFollowupDocument = Boolean(isFollowup);
    
        return (!isCompliantStatus && (isFollowup || item.showCalculatedByGroup));
    };

    service.sortData = (data, column, order, docTypeIdKey) => {
        data.sort((a, b) => {
            return calculateCompareFollowUps(
                a,
                b,
                column,
                docTypeIdKey,
                order
            );
        });
    }

    service.complianceGroupExtraSettings = {
        displayProp: 'name',
        showCheckAll: false,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        smartButtonTextConverter: function (itemText, originalItem) { return itemText; },
        styleActive: true,
        scrollable: true,
        scrollableHeight: '360px',
        enableSearch: true,
        searchField: 'name',
        closeOnSelect: true
    };
    
    service.getData = (params, items, docTypeIdKey, searchColumnName) => {
        const sorting = params.sorting();

        if (Object.keys(sorting).length > 0) {
            const [column, order] = Object.entries(sorting)[0];
            service.sortData(items, column, order, docTypeIdKey);
        }

        items = (params.filter()['$'] && searchColumnName)
            ? applySearchFilterDocumentsSettings(items, params.filter()['$'], searchColumnName)
            : items;

        params.total(items.length);

        return items.slice((params.page() - 1) * params.count(), params.page() * params.count());
    };

    service.getCaregiverComplianceTrackingActiveRowData = (params, items) => {
        // sort grouped items
        const sorting = params.sorting();
        if (Object.keys(sorting).length > 0) {
            const [column, order] = Object.entries(sorting)[0];
            service.sortGroupedItems(items, column, order);
        }

        // calc show, flattening to array
        items = service.calcShowGroupItemsIncompliances(items);
        items = service.flattenGroupDocuments(items);
        items = items.filter(item => item.showCalculatedByGroup);
        items = service.addStatusClassToItems(items);

        // follow-up sort
        if (Object.keys(sorting).length > 0) {
            const [column, order] = Object.entries(sorting)[0];
            items.sort((a, b) => {
                return calculateCompareFollowUps(
                    a,
                    b,
                    column,
                    'caregiverDocumentTypeId',
                    order
                );
            });
        }

        return items.slice((params.page() - 1) * params.count(), params.page() * params.count());
    };

    service.getCaregiverComplianceData = (params, items, docTypeIdKey, searchColumnName, selectedSection, sectionItemsCount) => {        
        // filter items
        items = (params.filter()['$'] && searchColumnName)
            ? applySearchFilter(items, params.filter()['$'], searchColumnName)
            : items;

        items = params.filter()['statuses'].length > 0
            ? applyStatusesFilter(items, params.filter()['statuses'])
            : items;

        service.setFollowupDocumentsCaregiverCompliance(items);

        // group items (build trees)
        items = service.buildGroupItemsTree(items);
        
        // sort grouped items
        const sorting = params.sorting();
        if (Object.keys(sorting).length > 0) {
            const [column, order] = Object.entries(sorting)[0];
            service.sortGroupedItems(items, column, order);
        }

        // calc show, flattening to array
        items = service.calcShowGroupItems(items);
        items = service.flattenGroupDocuments(items);
        items = items.filter(item => item.showCalculatedByGroup);
        items = service.addStatusClassToItems(items);

        // follow-up sort
        if (Object.keys(sorting).length > 0) {
            const [column, order] = Object.entries(sorting)[0];
            items.sort((a, b) => {
                return calculateCompareFollowUps(
                    a,
                    b,
                    column,
                    docTypeIdKey,
                    order
                );
            });
        }

        updateSectionCounts(sectionItemsCount, items);

        items = items.filter(item => item.sectionLabel === selectedSection);

        params.total(items.length);

        return items.slice((params.page() - 1) * params.count(), params.page() * params.count());
    };

    service.getCountGroupBy = (items, field) => {
        const res = {};
    
        for (const item of items) {
            res[item[field]] = (res[item[field]] ?? 0) + 1;
        }
    
        return res;
    }    

    service.sortGroupedItems = (groupedItems, column, order) => {
        // sort grouped items children and every parent
        groupedItems.forEach(
            (item) => service.sortGroupItem(item, column, order)
        );
         // sort all grouped parents by column
         if(order === "asc") {
            groupedItems.sort((group1, group2) => group1[column].localeCompare(group2[column]));
        } else {
            groupedItems.sort((group1, group2) => group2[column].localeCompare(group1[column]));
        }
    };

    service.sortGroupItem = (item, column, order) => {
        if(item.children) {
            if(order === "asc") {
                item.children.sort((child1, child2) => child1[column].localeCompare(child2[column]));
            } else {
                item.children.sort((child1, child2) => child2[column].localeCompare(child1[column]));
            }
            item.children.forEach(
                (child) => service.sortGroupItem(child, column, order)
            );
        }
    };

    service.setFollowupDocumentsCaregiverCompliance = (data) => {
        const followupMap = new Map();
        const followupParentsMap = getFollowupParentsMap(data);
        const result = [];

        data.forEach(doc => {
            doc.followupParentId = followupParentsMap.get(doc.caregiverDocumentTypeId);
            doc.isFollowupDocument = Boolean(doc.followupParentId);

            if (doc.isFollowupDocument) {
                followupMap.set(doc.caregiverDocumentTypeId, doc);
            } else {
                result.push(doc);
            }
        });

        result.forEach(doc => service.setFollowupDocumentForCaregiver(doc, followupMap));

        return result;
    };

    service.setFollowupDocumentsComplianceTracking = (data) => {
        const followupParentMap = new Map();
        const dataTree = [];

        data.forEach(doc => {
            const followupDocumentTypeId = doc.followupDocumentRequireData?.followupDocumentTypeId;
            if (followupDocumentTypeId) {
                followupParentMap.set(followupDocumentTypeId, doc);
            }
        });

        data.forEach(doc => {
            const parent = followupParentMap.get(doc.documentTypeId);

            if (parent) {
                parent.followupDocument = doc;
                doc.followupParentId = parent.documentTypeId;
                doc.isFollowupDocument = true;
            } else {
                doc.isFollowupDocument = false;
                dataTree.push(doc);
            }
        });

        return dataTree;
    }

    service.setFollowupDocumentForCaregiver = (doc, followupMap, tree) => {
        tree = tree || new Set();

        if (doc && doc.followupDocumentData && doc.followupDocumentData.followupDocumentTypeId) {
            if (tree.has(doc)) {
                console.error("Document type id is already in the followup tree. This will cause an infinite recursive calls.");
                return;
            }

            tree.add(doc);

            doc.followupDocument = followupMap.get(doc.followupDocumentData.followupDocumentTypeId);

            if (doc.followupDocument) {
                service.setFollowupDocumentForCaregiver(doc.followupDocument, followupMap, tree);
            }
        }
    };

    service.transformFieldBeforeRequest = (field) => {
        let value = null;

        switch (field.type) {
            case "Text":
                value = field.model || null;
                break;

            case "Date":
                value = field.model.value ? dateToLocalDate(field.model.value) : null;
                break;

            case "Dropdown":
                value = (field.model === null || field.model === undefined) ? null : field.possibleValues.find(pv => pv.id === parseInt(field.model));
                break;

            default:
                console.error(`Unhandled type: "${field.type}"`);
        }

        return {
            ...field,
            value
        };
    };

    service.setSelectedDocumentModelInitialState = (selectedDocument) => {
        return {
            caregiverDocumentTypeId: selectedDocument.caregiverDocumentTypeId,
            fields: selectedDocument.fields,
            files: null,
            isCompliant: false,
            effectiveDate: new Date(),
            expiryDate: null,
            expiryDateButtons: [
                {
                    title: "1 week",
                    active: false,
                    date: generateDateInDays(7),
                },
                {
                    title: "1 month",
                    active: false,
                    date: generateDateInDays(30),
                },
                {
                    title: "1 year",
                    active: false,
                    date: generateDateInDays(365),
                },
                {
                    title: "Custom date",
                    active: false,
                    date: new Date()
                },
            ],
        }
    };

    service.addStatusClassToItems = (items) => {
        items.forEach(item => {
            item.statusClasses = service.getStatusLabelClass(item.status)
        });

        return items;
    };

    service.getStatusLabelClass = (status) => {
        return {
            "styled-label-green": status === "Compliant",
            "styled-label-red": status === "Not Compliant",
            "styled-label-orange": status === "Missing",
        };
    };

    service.transformHistoryStatusChanges = (records) => {
        const result = [];
        let lastDate;

        for (let i = records.length - 1; i >= 0; i --) {
            const record = records[i];

            result.push({
                compliant: record.compliant,
                fromDate: record.effectiveDate,
                toDate: lastDate ? lastDate.subtract(1, 'days').format('YYYY-MM-DD') : null,
            });

            lastDate = moment(record.effectiveDate);
        }

        return result;
    };

    service.calculateDaysDiffFromToday = (date) => {
        const now = moment();
        const expirationDate = moment(date);
        return expirationDate.diff(now, 'days');
    };

    service.calculateDayToExpireAsText = (daysDiff) => {
        return calculateDaysAsText(daysDiff, "Expires", "Expired");
    };

    service.calculateDayToDueAsText = (daysDiff) => {
        return calculateDaysAsText(daysDiff, "Due", "Was due");
    };

    service.setItemFieldsModels = (fields) => {
        fields.forEach(field => {
            field.model = service.getFieldModelValue(field);
        });
    };

    service.getFieldModelValue = (field) => {
        switch (field.type) {
            case "Text":
                return field.value || "";

            case "Date":
                return {
                    value: stringToDate(field.value),
                    isOpened: false,
                };

            case "Dropdown":
                return field.value?.id.toString();
        }

        console.error(`Unhandled type "${fieldType}"`);
    };

    service.calculateDaysElapsed = (diff, prefixPresent, prefixPast) => {
        if (isNaN(diff)) {
            return null;
        }

        if (diff === 0) {
            return `${prefixPresent} today`;
        }

        if (diff >= 0) {
            return `${prefixPresent} in ${diff} day${diff > 1 ? `s` : ``}`;
        }

        return `${prefixPast} ${diff * -1} day${diff < -1 ? `s ago` : ` ago`}`;
    };

    service.calculateDaysToExpireDiff = (expiryDate) => {
        const now = moment();

        const expirationDate = moment(expiryDate);

        return expirationDate.diff(now, 'days');
    };

    service.getExpiryOrDueDateParams = (status, dueDate, expiryDate, requireReVerification) => {
        const hasDueDate = Boolean(dueDate);
        const missingHireDate = dueDate === '3000-01-01';
        const dateType = hasDueDate && expiryDate
            ? service.calculateDaysToExpireDiff(dueDate) > 0
                ? 'dueDate'
                : 'expiryDate'
            : hasDueDate
            ? 'dueDate'
            : 'expiryDate';
        const isUsingDueDate = dateType === 'dueDate';
        expiryDate = isUsingDueDate ? dueDate : expiryDate;
        const discardExpiry = dateType === 'expiryDate' && !requireReVerification;
        const daysToExpire = service.calculateDaysToExpireDiff(expiryDate);
        const isValidExpiry = discardExpiry || (!isNaN(daysToExpire) && daysToExpire >= 0);
        const daysToExpireTextColor = discardExpiry
            ? 'black'
            : !isValidExpiry || missingHireDate
            ? "red"
            : "green";
        
        return {
            discardExpiry,
            isUsingDueDate,
            data: {
                expiryDate: expiryDate,
                missingHireDate: missingHireDate,
                daysToExpire: daysToExpire,
                isValidExpiry: isValidExpiry,
                daysToExpireText: missingHireDate ? 'Missing hire date' : service.calculateDaysElapsed(
                    daysToExpire,
                    isUsingDueDate ? "Due" : "Expires",
                    isUsingDueDate ? "Was due" : "Expired",
                ),
                daysToExpireTextColor: daysToExpireTextColor,
                showDaysToExpire: expiryDate !== undefined && !isNaN(daysToExpire) && ((daysToExpireTextColor === 'green') || !service.compliantStatuses.includes(status)),
                daysToExpireSort: isNaN(daysToExpire) ? 'Ω' : daysToExpire, // Sorting with null values is inconsistent
            },
        };
    };

    return service;
});

function calculateCompareFollowUps(
    a,
    b,
    column,
    docTypeIdKey,
    order
) {
    const isAfollowUp = a.followupDocumentData !== null || a.isFollowupDocument;
    const isBfollowUp = b.followupDocumentData !== null || b.isFollowupDocument;
    // WE WANT TO COMPARE ONLY 2 FOLLOW-UP DOCUMENTS
    if (!isAfollowUp || !isBfollowUp) return 0;

    let compare = ('' + a.root[column]).localeCompare(b.root[column]);

    if (compare === 0) {
        compare = ('' + a.root[docTypeIdKey]).localeCompare(b.root[docTypeIdKey]);
    }

    if (compare === 0) {
        return a.depth - b.depth;
    }

    return order === 'desc' ? compare * -1 : compare;
}

function isCompliant(doc) {
    return doc.instances.length > 0 && doc.instances[0].isCompliant;
}

function dateToLocalDate(date) {
    if (date) {
        return LocalDate.from(nativeJs(moment(date)));
    }
    return undefined;
}

function generateDateInDays(days) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + days);
    return date;
}

function applySearchFilterDocumentsSettings(documents, query, searchColumnName) {
    const roots = new Set(
        documents
            .filter((doc) =>
            doc[searchColumnName]
                .toLowerCase()
                .includes(query.toLowerCase())
            )
            .map((doc) => doc.root)
    );

    return documents.filter(doc => roots.has(doc.root));
}

function applySearchFilter(items, query, searchColumnName) {
    return items
        .filter((item) =>
        item[searchColumnName]
            .toLowerCase()
            .includes(query.toLowerCase())
        );
}

function applyStatusesFilter(items, statusesOptions) {
    const statuses = new Set(statusesOptions.map(s => s.id));

    return items
        .filter((item) =>
            statuses.has(item.status)
        );
}

function stringToDate(str) {
    if (str) {
        const d = JSJoda.LocalDate.parse(str);
        return new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
    }

    return null;
}

function calculateDaysAsText(diff, prefixPresent, prefixPast) {
    if (isNaN(diff)) {
        return null;
    }

    if (diff === 0) {
        return `${prefixPresent} today`;
    }

    if (diff >= 0) {
        return `${prefixPresent} in ${diff} day${diff > 1 ? `s` : ``}`;
    }

    return `${prefixPast} ${diff * -1} day${diff < -1 ? `s ago` : ` ago`}`;
}

function updateSectionCounts(sectionItemsCount, items) {
    for (const section of Object.keys(sectionItemsCount)) {
        sectionItemsCount[section] = items.filter(i => i.sectionLabel === section).length;
    }
}

function getFollowupParentsMap(items) {
    const followupMap = new Map();

    for (const item of items) {
        if (item.followupDocumentData && item.followupDocumentData.followupDocumentTypeId) {
            followupMap.set(item.followupDocumentData.followupDocumentTypeId, item.caregiverDocumentTypeId);
        }
    }

    return followupMap;
}