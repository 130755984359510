angular
  .module("dashboard")
  .factory("rangesCalculateService", function ($rootScope) {
  
    const service = {};

    service.calculateRanges = (currObjects) => {
        const rangesDisplay = [];
        const seen = [];
        // Create datetimes from dates - for one day rate range to be possible when taking unique dates for calculation
        currObjects = DatesToDatetime(currObjects);
        const startDatetimes = currObjects.map(item =>({ datetime: item.startDateTime, type: "START" }));
        const endDatetimes = currObjects.map(item => ({ datetime: item.endDateTime, type: "END" }));
        const uniqueDatetimes = [...startDatetimes, ...endDatetimes].filter(dateObj => {
            const duplicate = seen.find(item => item.isSame(dateObj.datetime));
            seen.push(dateObj.datetime);
            return !duplicate;
        });
        const uniqueDatetimesSorted = uniqueDatetimes.map(dateObj => ({...dateObj, datetime: dateObj.datetime})).sort((a, b) => a.datetime.diff(b.datetime));
        let dateIndex = 0;

        while(dateIndex < uniqueDatetimesSorted.length - 1) {
            const curr = calculateNewest(currObjects, uniqueDatetimesSorted[dateIndex]);

            // Check if currDate is the start date or end date of the newest object on this day
            if (curr.isNewestObjectIncludesDate) {
                let next = calculateNewest(currObjects, uniqueDatetimesSorted[dateIndex + 1]);

                // While the nextDate is not the start date or end date of the newest object on this day - skip and get the next one
                while (!next.isNewestObjectIncludesDate) {
                    dateIndex++;
                    next = calculateNewest(currObjects, uniqueDatetimesSorted[dateIndex + 1]);
                }

                if (curr.dateObj.type === "START" && next.dateObj.type === "START") {
                    if (curr.dateObj.datetime.startOf('day').diff(next.dateObj.datetime.startOf('day'), 'days') !== -1) {
                        rangesDisplay.push(createRangeObj(curr.dateNewestObject, curr.dateObj.datetime, next.dateObj.datetime.clone().subtract(1, 'd')));
                    }
                } else if (curr.dateObj.type === "START" && next.dateObj.type === "END") {
                    rangesDisplay.push(createRangeObj(curr.dateNewestObject, curr.dateObj.datetime, next.dateObj.datetime));
                } else if (curr.dateObj.type === "END" && next.dateObj.type === "END") {
                    rangesDisplay.push(createRangeObj(next.dateNewestObject, curr.dateObj.datetime.clone().add(1, 'd'), next.dateObj.datetime));
                } else if (curr.dateObj.type === "END" && next.dateObj.type === "START") {
                    if (curr.dateObj.datetime.startOf('day').diff(next.dateObj.datetime.startOf('day'), 'days') !== -1) {
                        const betweenGap = calculateNewest(currObjects, {...uniqueDatetimesSorted[dateIndex], datetime: uniqueDatetimesSorted[dateIndex].datetime.clone().add(1, 'd')});

                        if (betweenGap.dateNewestObject !== undefined) {
                            rangesDisplay.push(createRangeObj(betweenGap.dateNewestObject, curr.dateObj.datetime.clone().add(1, 'd'), next.dateObj.datetime.clone().subtract(1 , 'd')));
                        }
                    }
                }
            }
            
            dateIndex++;
        }

        return rangesDisplay;
    };

    function createRangeObj(obj, startDate, endDate) {
        return ({
            ...obj,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD')
        })
    };

    function calculateNewest(currObjects, dateObj) {
        const dateObjectIntersect = currObjects.filter(item => 
            dateObj.datetime.isSameOrAfter(item.startDateTime) && dateObj.datetime.isSameOrBefore(item.endDateTime));
        const dateNewestObject = dateObjectIntersect.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))[dateObjectIntersect.length - 1];
        const isNewestObjectIncludesDate = dateNewestObject === undefined ? false : dateNewestObject.startDateTime.isSame(dateObj.datetime) || dateNewestObject.endDateTime.isSame(dateObj.datetime);
        
        return {
            dateObj, 
            dateNewestObject, 
            isNewestObjectIncludesDate
        }
    }

    function DatesToDatetime(currObjects) {
        return currObjects.map(item => {
            item.startDateTime = moment(item.startDate);

            if (item.startDate !== item.endDate) {
                item.endDateTime = moment(item.endDate);
            } else {
                item.endDateTime = moment(item.endDate).endOf('d');   
            }

            return item;
        })
    }

    return service;
});