"use strict";

angular.module("dashboard").factory("AgencySettingsService", (DatabaseApi) => ({
  getSettings: () => {
    return {
      allowBoost: "Allow boost",
      shouldSendInvoice: "Should Send Invoice"
    };
  },

  getSubscriptions: () => {
    return {
      withPrizes: "SMS with prizes",
      withoutPrizes: "SMS without prizes",
      training: "Training SMS",
      corona: "COVID-19 SMS",
      evvInitial: "EVV Initial SMS",
      downloadInitial: "Download initial", // before    makeCalls: "Make calls",
      downloadOngoing: "Download ongoing",
      trainingInitial: "Training initial",
      trainingOngoing: "Training ongoing"
    };
  },

  /**
   * Update Stripe Customer ID
   */
  updateStripeCustomerId: (agencyId, stripeCustomerId) => {
    const postData = {
      id: agencyId,
      stripeCustomerId: stripeCustomerId,
    };

    return DatabaseApi.post("admin/agencies/stripeCustomerId", postData);
  },

  /**
   * Change an Agency Setting
   */
  changeSetting: (taskId, value, { agencyId }) => {
    const postData = {
      id: agencyId,
      [taskId]: value,
    };

    return DatabaseApi.post(`admin/agencies/${taskId}`, postData);
  },

  /**
   * Change SMS Subscription
   */
  changeSubscription: (agencyId, subscriptions) => {
    const postData = {
      agencyId: agencyId,
      ...subscriptions
    };

    return DatabaseApi.post("admin/agencies/subscribeToSms", postData);
  },
}));
