/**
 * A safe way of converting between major and minor currencies
 *
 * Example:
 *  - Invalid: 163.8 * 100; // = 16380.000000000002
 *  - Valid:   new Currency().fromMajor(163.8).toMinor(); // = 16380
 */
angular.module("dashboard").factory("Currency", () => {
    return class Currency {
        major;
        minor;

        fromMajor(value, dflt) {
            // parseFloat is kind of messed up. parseFloat("1qweqeqeqe") === parseFloat("1")
            const number = parseFloat(value, 10);

            if (Number.isNaN(number)) {
                if (dflt) {
                    return this.fromIntegerMajor(0);
                } else {
                    return this.fromNaN();
                }                
            }

            return Number.isInteger(number)
                ? this.fromIntegerMajor(number)
                : this.fromDoubleMajor(number)
        }

        fromIntegerMajor(number) {
            this.major = number;
            this.minor = 0;

            return this;
        }

        fromNaN() {
            this.major = Number.NaN;
            this.minor = Number.NaN;

            return this;
        }

        isNaN() {
            return Number.isNaN(this.major) || Number.isNaN(this.minor);
        }

        fromDoubleMajor(number) {
            const matches = /^(-?)([0-9,\.]+)[\.|,](\d+)$/g.exec(number.toString());

            if (matches === null) {
                console.error(`Failed to parse fromDoubleMajor the value "${number}"`);
                return this;
            }

            const [_, optionalNegation, major, minor] = matches;

            const sign = optionalNegation === "-" ? -1 : 1;

            this.major = sign * parseInt(major.replace(/[\.,]/g, ""), 10);

            const minorStr = minor.replace(/[\.,]/g, "");

            this.minor = sign * parseInt(minorStr, 10) * (minorStr.length === 1 ? 10 : 1);

            return this;
        }

        toMinor() {
            if (Number.isInteger(this.major) && Number.isInteger(this.minor)) {
                return (this.major * 100) + this.minor;
            } else {
                console.error(`Currency.toMinor: non-integer major (${this.major}) or minor (${this.minor}).`, )
            }
        }
    }
})
