angular
  .module("dashboard")
  .factory("CaregiverModalService", ($rootScope, DatabaseApi, wildcard) => ({
    /**
     * Get all visits of a caregiver
     */
    getCaregiverVisits(caregiverId) {
      const { agencyId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/caregivers/:caregiverId/assigned_visits",
        agencyId,
        caregiverId
      );

      return DatabaseApi.get(endpoint).then((res) => mapVisits(res.data));
    },
    /**
     * Cancel imported visit
     */
    cancelImportedVisit(importedVisitId) {
      const { agencyId, agencyMemberId } = $rootScope;

      const endpoint = wildcard(
        "agencies/:agencyId/agency_members/:agencyMemberId/imported_visits/:imported_visit",
        agencyId,
        agencyMemberId,
        importedVisitId
      );

      return DatabaseApi.put(endpoint);
    },
  }));

const mapVisits = ({ assignedVisits, importedVisits, scheduledVisits }) => {
  const visits = [
    ...assignedVisits.map(mapAssigned),
    ...scheduledVisits.map(mapScheduled),
    ...importedVisits.map(mapImported),
  ];
  return visits.map((oldVisit, index) => {
    const visit = {
      ...oldVisit,
      index,
    };

    if (visit.visitSourceType === "IMPORTED") {
      visit.type = "Imported";
    } else if (visit.isSingleVisit) {
      visit.type = "Single";
    } else {
      visit.type = "Recurring";
    }

    return visit;
  });
};

const mapAssigned = (visit) => ({
  ...visit,
  visitSourceType: "ASSIGNED",
});

const mapImported = (visit) => ({
  ...visit,
  visitSourceType: "IMPORTED",
});

const mapScheduled = (visit) => ({
  ...visit,
  visitSourceType: "SCHEDULED",
  startTime: visit.scheduledStartTimeLocal,
  endTime: visit.scheduledEndTimeLocal,
});
