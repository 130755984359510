
    const getStatuses = () =>{
      return [
              {
                btnClass: "primary",
                pastText: "Approved",
                actionText: "Approve",
                id: "APPROVED",
                permissionKey: "allow_visit_instance_adjustment_approve",
              },
              {
                btnClass: "warning",
                pastText: "Ignored",
                actionText: "Ignore",
                id: "IGNORED",
                permissionKey: "allow_visit_instance_adjustment_ignore",
              },
              {
                btnClass: "danger",
                pastText: "Rejected",
                actionText: "Reject",
                id: "REJECTED",
                permissionKey: "allow_visit_instance_adjustment_reject",
              },
            ];
      }
     
      const getDiffsMap = () => {
          return {
              admissionDateChange: "Admission Date",
              authorizationNumberChange: "Authorization Number",
              billingIssuesChange: "Billing Issues",
              billingRateChange: "Billing Rate",
              billingUnitsChange: "Billing Units",
              caregiverChange: "Caregiver",
              contractChange: "Contract",
              diagnosisCodesChange: "Diagnosis Codes",
              exportAndRevenueCodeChange: "Export And RevenueCode",
              isHolidayChange: "Is Holiday",
              isMutualChange: "Is Mutual",
              locationChange: "Location",
              missedVisitChange: "MissedVisit",
              officeChange: "Office",
              patientChange: "Patient",
              patientInfoChange: "Patient Info",
              payrollCodeChange: "Payroll Code",
              payrollIssuesChange: "Payroll Issues",
              payrollTimeChange: "Payroll Time",
              recordedTimeChange: "Recorded Time",
              removedChange: "Removed",
              scheduleChange: "Schedule",
              serviceChange: "Service",
              spreadOfHoursRateChange: "Spread Of Hours Rate",
              visitDateChange: "Visit Date",
              visitRateChange: "Visit Rate",
              visitTypeChange: "Visit Type",
            };
      };
      const getDiffsExtraSettings =()=>{ 
          return {
          styleActive: true,
          selectedToTop: true,
          scrollable: true,
          enableSearch: true,
        };
      }
app.constant('adjustmentApprovalsConsts', {


    
      statuses:getStatuses(),
      diffsMap:getDiffsMap(),
      diffsExtraSettings: getDiffsExtraSettings(),
      diffsOptions: Object.keys(getDiffsMap()).map((key, idx) => ({
        id: idx,
        value: key,
        label: getDiffsMap()[key],
      }))
      
    }
  );