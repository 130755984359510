angular
  .module("dashboard")
  .factory("VisitFileUploadService", ($http, Consts, wildcard) => ({
    /**
     * Get history of visit file uploads
     */
    getHistory: ({ agencyId, type }) => {
      const url = Consts.api + "agencies/:agencyId/table_file_upload";
      return $http
        .get(wildcard(url, agencyId))
        .then((res) => res.data.list.filter((row) => row.type === type));
    },

    /**
     * Upload one or more visit files
     */
    uploadFiles: ({ agencyId, agencyMemberId, type, files }) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_members/:agencyMemberId/visits_file_upload";

      const formData = new FormData();
      for (const file of files) {
        formData.append("files", file, file.name);
      }
      formData.append("type", type);

      return $http({
        url: wildcard(url, agencyId, agencyMemberId),
        method: "POST",
        data: formData,
        headers: { "Content-Type": undefined },
      });
    },
  }));
