angular
  .module("dashboard")
  .factory("ptoNotesModalService", ($uibModal, DatabaseApi, $q) => {
    const notesData = {};
    let handlePostNote;
    let handleDeleteNote;
    let notesModalInstance;

    const setNotesData = ({
      profileId,
      profileName,
      caregiverId
    }) => {
      // Set notes data (exported later)
      notesData.profileId = profileId;
      notesData.profileName = profileName;
      notesData.caregiverId = caregiverId;
    };

    const handleNotesModalOpen = ({
      profileId,
      profileName
    }) => {
      setNotesData({
        profileId,
        profileName
      });

      notesModalInstance = $uibModal.open({
        templateUrl: "admin/views/pto-approval-notes-modal.html",
        size: "md",
        controller: "ptoApprovalNotesModalCtrl",
        windowClass: "modal modal-slide-in-right entity-notes-container",
      });
      return notesModalInstance;
    };

    const closeModal = () => {
      if (notesModalInstance !== undefined) {
        notesModalInstance.close();
      }
    }

    const setHandlePostNote = (handlePostNoteFunc) => {
      handlePostNote = handlePostNoteFunc;
    }

    const onPostNote = () => {
      handlePostNote();
    }

    const setHandleDeleteNote = (handleDeleteNoteFunc) => {
      handleDeleteNote = handleDeleteNoteFunc;
    }

    const onDeleteNote = () => {
      handleDeleteNote();
    }

    return {
      notesData,
      closeModal,
      setNotesData,
      handleNotesModalOpen,
      setHandlePostNote,
      onPostNote,
      setHandleDeleteNote,
      onDeleteNote
    };
  });
