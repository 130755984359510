angular
  .module("dashboard")
  .factory("allergyService", function ($http, Consts, wildcard) {
    /* Decalare factory return object */
    const service = {};
    service.allergies = undefined;

    /**
     * Get a list of allergies
     */
    service.getAllergies = (agencyId) => {
      const url =
        Consts.api +
        "agencies/:agencyId/allergies";

      return $http.get(wildcard(url, agencyId)).then((res) => {
        service.allergies = res.data.allergies;
        return service.allergies;
      });
    };

    /**
     * Get a list of patient allergies
     */
    service.getPatientAllergies = (agencyId, agencyMemberId, patientId) => {
      const url =
        Consts.api +
        "agencies/:agencyId/agency_member/:agencyMemberId/patients/:patientId/allergies";

      return $http.get(wildcard(url, agencyId, agencyMemberId, patientId)).then((res) => {
        service.patientAllergies = res.data.allergies;
        return service.patientAllergies;
      });
    };


    /**
     * Update patient allergies
     */
    service.updatePatientAllergies = (agencyId, agencyMemberId, patientId, allergyIdsObject) => {
      const url = 
        Consts.api +
        "agencies/:agencyId/agency_member/:agencyMemberId/patients/:patientId/allergies";

      return $http.put(wildcard(url, agencyId, agencyMemberId, patientId), allergyIdsObject);
    };

    return service;
});