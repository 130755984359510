angular
  .module("dashboard")
  .factory("reportService", function ($rootScope, $http, Consts) {
    /* Decalare factory return object */
    const service = {};

    service.getReportsSchema = () => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/reports/schema`).then(res => {
        return res.data;
      })
    }

    service.getSavedReportSchema = () => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/reports/saved_report_schema`).then(res => {
        return res.data;
      })
    }

    service.getMySavedReports = () => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/my_saved_reports`).then(res => {
        return res.data.list;
      })
    }

    service.getSharedSavedReports = () => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/shared_saved_reports`).then(res => {
        return res.data.list;
      })
    }
    service.getStandardReports = () => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/standard_reports`).then(res => {
        return res.data.list;
      })
    }
  
    service.updateStandardReportSettings = (standardReportId, standardReportSettingsParams) => {
      return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/standard_reports/${standardReportId}/settings`, standardReportSettingsParams);
    }
    service.executeReport = (executeReportParams) => {
      return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/reports/request`, executeReportParams).then(res => {
        return res.data;
      })
    }
    service.executeCustomReport = (executeReportParams) => {
      return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/reports/custom_request`, executeReportParams).then(res => {
        return res.data;
      })
    }
    service.executeSavedReport = (savedReportId, timezone) => {
      return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/reports/request_saved/${savedReportId}`, { timezone: timezone }).then(res => {
        return res.data;
      })
    }


    service.getStandardReport = (standardReportId) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/standard_reports/${standardReportId}`).then(res => {
        return res.data;
      })
    }
  // ============ Standard report access ========

  service.getStandardReportRolesAccess = (savedReportId) => {
    return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/standard_reports/${savedReportId}/access`).then(res => {
      return res.data.list;
    })
  }
  service.getStandardReportAgencyMembersAccess = (savedReportId) => {
    return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/standard_reports/${savedReportId}/agency_member_access`).then(res => {
      return res.data.list;
    })
  }
  service.addStandardReportRoleAccess = (standardReportId, standardReportAccessParams) => {
    return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/standard_reports/${standardReportId}/access`, standardReportAccessParams);
  }
  service.deleteStandardReportRoleAccess = (standardReportId, roleId) => {
    return $http.delete(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/standard_reports/${standardReportId}/access/${roleId}`);
  }
  
  service.deleteStandardReportAgencyMemberAccess = (standardReportId, agencyMemberId) => {
    return $http.delete(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/standard_reports/${standardReportId}/agency_member_access/${agencyMemberId}`);
  }
  service.addStandardReportAgencyMembersAccess = (standardReportId, standardReportAccessParams) => {
    return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/standard_reports/${standardReportId}/agency_member_access`, standardReportAccessParams);
  }
  // ====
    service.getSavedReport = (savedReportId) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/saved_report/${savedReportId}`).then(res => {
        return res.data;
      })
    }

    service.removeSavedReport = (savedReportId) => {
        return $http.delete(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/saved_report/${savedReportId}`);
      }

    service.createSavedReport = (savedReportCreateParams) => {
      return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/saved_report`, savedReportCreateParams);
    }

    service.editSavedReport = (savedReportId, savedReportEditParams) => {
      return $http.put(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/saved_report/${savedReportId}`, savedReportEditParams);
    }
    
    // ============ Saved report access ========

    service.getSavedReportRolesAccess = (savedReportId) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/saved_report/${savedReportId}/access`).then(res => {
        return res.data.list;
      })
    }

    service.addSavedReportRolesAccess = (savedReportId, addRolesAccessParams) => {
      return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/saved_report/${savedReportId}/access`, addRolesAccessParams);
    }

    service.editSavedReportRoleAccess = (savedReportId, editRoleAccessParams) => {
      return $http.put(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/saved_report/${savedReportId}/access`, editRoleAccessParams);
    }


    service.deleteSavedReportRoleAccess = (savedReportId, roleId) => {
      return $http.delete(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/saved_report/${savedReportId}/role/${roleId}`);
    }

    service.getReportEntityRoles = () => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/reports/entities_roles`).then(res => {
        return res.data.entityRoles;
      })
    }

    service.editReportEntityRole = (reportEntityRoleEditParams) => {
      return $http.put(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/reports/entity_role`, reportEntityRoleEditParams);
    }

    // ============ Saved report scheduled ========

    service.getAllScheduledWithHistoryBySavedReport = (savedReportId) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/saved_report/${savedReportId}/scheduled_history`).then(res => {
        return res.data.list;
      })
    }

    service.addScheduledSavedReport = (savedReportId, scheduledSavedReportParams) => {
      return $http.post(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/saved_report/${savedReportId}/scheduled`, scheduledSavedReportParams);
    }

    service.toggleActivateScheduledSavedReport = (scheduledSavedReportId, params) => {
      return $http.put(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/scheduled_saved_report/${scheduledSavedReportId}`, params);
    }

    service.downloadReportFile = (scheduledSavedReportId, scheduledSavedReportHistoryId) => {
      return $http.get(`${Consts.api}agencies/${$rootScope.agencyId}/agency_members/${$rootScope.agencyMemberId}/scheduled_saved_report/${scheduledSavedReportId}/history/${scheduledSavedReportHistoryId}`).then(res => {
        return res.data.url;
      })
    }

    return service;
});
